import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from "@angular/core";
import { MeetingPatternGrid, MeetingPatternGridService } from "../s25-swarm-schedule/meeting.pattern.grid.service";
import { Grid } from "../s25-virtual-grid/s25.virtual.grid.component";
import { Bind } from "../../decorators/bind.decorator";
import { SwarmSchedule } from "../s25-swarm-schedule/SwarmSchedule";
import { S25Util } from "../../util/s25-util";
import { ContactService } from "../../services/contact.service";
import { UserprefService } from "../../services/userpref.service";
import { DowGrid, S25DowGridComponent } from "../s25-virtual-grid/s25.dow.grid.component";
import { Proto } from "../../pojo/Proto";
import { BoardUploadService } from "../s25-swarm-schedule/board.upload.service";
import { MPGUtil } from "./s25.meeting.pattern.grid.util";
import EpochTimestamp = Proto.EpochTimestamp;
import Milliseconds = Proto.Milliseconds;
import { S25Const } from "../../util/s25-const";
import { CacheRepository } from "../../decorators/cache.decorator";
import { BOARD_CONST } from "../s25-swarm-schedule/s25.board.const";
import { Throttle } from "../../decorators/debounce.decorator";
import { GridUtil } from "../s25-virtual-grid/s25.grid.util";
import { S25ModalComponent } from "../s25-modal/s25.modal.component";
import { BoardService } from "../s25-swarm-schedule/s25.board.service";
import { S25ItemI } from "../../pojo/S25ItemI";
import { AriaLive } from "../../services/aria.live.service";
import { Telemetry } from "../../decorators/telemetry.decorator";
import ISODateString = Proto.ISODateString;

@Component({
    selector: "s25-ng-meeting-pattern-grid",
    template: `
        <h2 class="board-name">{{ board.boardName }}</h2>
        <s25-ng-dow-pattern-grid
            *ngIf="prefs"
            [dataSource]="dowGridDataSource"
            [dows]="allowXDrag && allDows"
            [visibleDows]="prefs.dows"
            [startHour]="prefs.startHour"
            [endHour]="prefs.endHour"
            [rowHeaderTemplate]="defaultRowHeaderTemplate"
            [itemTemplate]="defaultItemTemplate"
            [optionsLeftTemplate]="optionsLeft"
            [optionsRightTemplate]="optionsRight"
            [optionsBelowTemplate]="optionsBelow"
            [canDragX]="allowXDrag"
            [canDragY]="true"
            [hasRefresh]="false"
            [hasMinimap]="true"
            [hasUndo]="true"
            [allowOverlap]="true"
            [displayShadows]="true"
            [pollForChanges]="board.shared || !!board.sharedUsers?.length"
            [pollInterval]="pollInterval"
        ></s25-ng-dow-pattern-grid>

        <ng-template #optionsLeft let-defaultOptions="defaultOptions">
            <button class="aw-button aw-button--danger--outline exit" (click)="exit.emit(false)">Exit</button>
            <button *ngIf="!board.shared" class="aw-button aw-button--outline share" (click)="onShare()">Share</button>
            <ng-container [ngTemplateOutlet]="defaultOptions"></ng-container>
        </ng-template>

        <ng-template #optionsRight let-defaultOptions="defaultOptions">
            <ng-container [ngTemplateOutlet]="defaultOptions"></ng-container>
            <button class="aw-button aw-button--outline toggle-filters" (click)="toggleFilters()">
                <s25-ng-icon [type]="filters.show ? 'caretUp' : 'caretDown'"></s25-ng-icon>
                {{ filters.show ? "Hide" : "Show" }} Location Filters
            </button>
            <s25-ng-button
                *ngIf="sortOrder !== 'default'"
                [type]="'outline'"
                [isLoading]="loading.sort"
                (click)="resetSort()"
                class="reset-sort"
            >
                Reset Sort
            </s25-ng-button>
            <s25-ng-button
                *ngIf="board.isOwner"
                [type]="'primary'"
                [isLoading]="loading.save"
                (click)="onSave()"
                class="send-to-optimizer"
            >
                Send to Optimizer
            </s25-ng-button>
        </ng-template>

        <ng-template #optionsBelow let-defaultOptions="defaultOptions">
            <!-- NOTE: display=none is used to avoid recalculating styles when we show the filters -->
            <div class="filters" [style.display]="filters.show ? 'flex' : 'none'">
                <label class="ngBold">Filters:</label>
                <div class="options">
                    <div class="name">
                        <label for="location-name-filter">Name</label>
                        <input
                            [(ngModel)]="filters.name"
                            (keydown.enter)="runFilters()"
                            id="location-name-filter"
                            type="text"
                            class="c-input"
                        />
                    </div>
                    <div class="capacity">
                        <label>Capacity</label>
                        <div class="capacity-value">
                            <label for="location-capacity-filter-between" class="above">between</label>
                            <input
                                [(ngModel)]="filters.capacityMin"
                                (keydown.enter)="runFilters()"
                                id="location-capacity-filter-between"
                                type="number"
                                min="0"
                                class="c-input"
                            />
                        </div>
                        <div class="capacity-value">
                            <label for="location-capacity-filter-and" class="above">and</label>
                            <input
                                [(ngModel)]="filters.capacityMax"
                                (keydown.enter)="runFilters()"
                                id="location-capacity-filter-and"
                                type="number"
                                min="0"
                                class="c-input"
                            />
                        </div>
                    </div>
                    <div>
                        <label for="location-features-filter" class="above"
                            >{{ filters.features.length }} Selected</label
                        >
                        <s25-ng-multiselect-search-criteria
                            [type]="'locationFeatures'"
                            [selectedItems]="filters.features"
                            [popoverOnBody]="true"
                        ></s25-ng-multiselect-search-criteria>
                    </div>
                    <div>
                        <label for="location-partitions-filter" class="above"
                            >{{ filters.partitions.length }} Selected</label
                        >
                        <s25-ng-multiselect-search-criteria
                            [type]="'locationPartitions'"
                            [selectedItems]="filters.partitions"
                            [popoverOnBody]="true"
                        ></s25-ng-multiselect-search-criteria>
                    </div>
                </div>
                <div class="buttons">
                    <s25-ng-button [type]="'outline'" [isLoading]="loading.filter" (click)="runFilters()">
                        Run Filters
                    </s25-ng-button>
                    <button class="aw-button aw-button--danger--outline clear-filters" (click)="clearFilters()">
                        Clear Filters
                    </button>
                </div>
            </div>
        </ng-template>

        <ng-template #defaultRowHeaderTemplate let-header="header">
            <span *ngIf="header.id === -1">{{ header.data.name }}</span>
            <s25-item-space
                *ngIf="header.id !== -1"
                [modelBean]="{ itemId: header.id, itemName: header.data.name }"
                [newTab]="true"
                class="ngAnchor"
            ></s25-item-space>
        </ng-template>

        <ng-template #defaultItemTemplate let-item="item" let-dragging="dragging" let-moving="moving">
            <s25-ng-meeting-pattern-grid-event
                [item]="item"
                [dragging]="dragging"
                [moving]="moving"
                [sortedBy]="sortOrder === 'relevance' && item === relevanceSortItem"
                (sortByRelevance)="onSortByRelevance($event)"
            ></s25-ng-meeting-pattern-grid-event>
        </ng-template>

        <s25-ng-modal #shareModal [title]="'Share Board'" [type]="'save'" [size]="'xs'" (save)="onShareSave()">
            <ng-template #s25ModalBody>
                <div class="share-modal">
                    <div class="shareWith">
                        <label class="ngBold"> Share With: </label>
                        <s25-ng-dropdown-search-criteria
                            [type]="'contacts'"
                            [(chosen)]="voidContact"
                            [customFilterValue]="'&is_r25user=1&itemName=username'"
                            (chosenChange)="addSharedUser($any($event))"
                        ></s25-ng-dropdown-search-criteria>
                    </div>
                    <div class="users">
                        <label class="ngBold">Username</label>
                        <div></div>
                        <ng-container *ngFor="let sharee of sharedUsers; let index = index">
                            <div>{{ sharee.username }}</div>
                            <button
                                class="aw-button aw-button--danger--outline unshare"
                                (click)="sharedUsers.splice(index, 1)"
                            >
                                Unshare
                            </button>
                        </ng-container>
                    </div>
                </div>
            </ng-template>
        </s25-ng-modal>

        <s25-ng-modal #overlapModal [title]="'Overlapping Sections'" [type]="'acknowledge'" [size]="'xs'">
            <ng-template #s25ModalBody>
                <p>
                    There are {{ overlapData.length }} outstanding sets of overlapping sections which need to be moved
                    before sending to optimizer.
                </p>
                <p>Overlapping sections are marked with blue borders in the grid.</p>
                <div *ngFor="let overlap of overlapData" class="overlapData">
                    <label>Location:</label>
                    <span>{{ overlap.roomName }}</span>
                    <label>Meeting Pattern:</label>
                    <span>{{ overlap.dow }}</span>
                    <label>Time:</label>
                    <span>{{ overlap.startTime }} - {{ overlap.endTime }}</span>
                    <label>Section A:</label>
                    <span>{{ overlap.sectionA }}</span>
                    <label>Section B:</label>
                    <span>{{ overlap.sectionB }}</span>
                </div>
            </ng-template>
        </s25-ng-modal>

        <s25-ng-modal #confirmOverlap [title]="'Warning: Overlapping Sections'" [type]="'confirm'" [size]="'xs'">
            <ng-template #s25ModalBody>
                <div class="confirmOverlap">
                    <p>
                        You are attempting to move a section into a room that is currently occupied by another section.
                        You will need to relocate at least one of these sections prior to sending your results to the
                        Optimizer.
                    </p>

                    <p class="ngBold">Do you wish to continue?</p>

                    <div class="overlapData">
                        <label>Location:</label>
                        <span>{{ overlapData[0].roomName }}</span>
                        <label>Meeting Pattern:</label>
                        <span>{{ overlapData[0].dow }}</span>
                        <label>Time:</label>
                        <span>{{ overlapData[0].startTime }} - {{ overlapData[0].endTime }}</span>
                        <label>Section A:</label>
                        <span>{{ overlapData[0].sectionA }}</span>
                        <label>Section B:</label>
                        <span>{{ overlapData[0].sectionB }}</span>
                    </div>
                </div>
            </ng-template>
        </s25-ng-modal>

        <s25-ng-modal #confirmUndersizedRoom [title]="'Warning: Undersized Location'" [type]="'confirm'" [size]="'xs'">
            <ng-template #s25ModalBody>
                <div class="confirmUndersizedRoom">
                    <p>You are attempting to move a section into a location which cannot support its headcount.</p>

                    <p class="ngBold">Do you wish to continue?</p>

                    <div class="undersizedRoomData">
                        <label>Section:</label>
                        <span>{{ undersizedRoomData.event.data.name }}</span>
                        <label>Headcount:</label>
                        <span>{{ undersizedRoomData.event.data.headCount }}</span>
                        <label>Location:</label>
                        <span>{{ undersizedRoomData.room.data.name }}</span>
                        <label>Max Capacity:</label>
                        <span>{{ undersizedRoomData.room.data.maxCapacity }}</span>
                    </div>
                </div>
            </ng-template>
        </s25-ng-modal>
    `,
    styles: `
        :host {
            --dow-separator-thickness: 2px;
        }

        .board-name {
            text-align: center;
            margin: 0;
            padding: 0.5em;
        }

        .share-modal {
            min-height: min(30rem, 75svh);
        }

        .share-modal .users {
            display: grid;
            gap: 0.5rem;
            grid-template-columns: 1fr 5rem;
        }

        .share-modal .shareWith {
            display: flex;
            flex-wrap: wrap;
            gap: 0.5rem;
            align-items: center;
            padding-bottom: 1rem;
        }

        .share-modal .shareWith s25-ng-dropdown-search-criteria {
            flex: 1;
        }

        .filters {
            justify-content: space-between;
            padding-bottom: 0.5em;
            padding-top: 1.5em;
        }

        .filters,
        .filters .options,
        .filters .options .name,
        .filters .options .capacity,
        .filters .buttons {
            display: flex;
            gap: 0.5rem;
            align-items: center;
            flex-wrap: wrap;
        }

        .filters .options {
            gap: 1em;
            row-gap: 1.5rem;
        }

        .toggle-filters {
            width: 14.5em;
            display: flex;
            justify-content: space-between;
        }

        .filters .capacity-value {
            width: 4em;
        }

        .filters :has(> label.above) {
            position: relative;
        }

        .filters label.above {
            font-size: 0.9em;
            text-align: center;
            position: absolute;
            translate: 0 -100%;
            width: 100%;
            padding-bottom: 0.25em;
        }

        .filters .capacity-value input {
            width: 100%;
            padding-left: 0.25em;
            padding-right: 0;
        }

        button {
            min-width: fit-content;
        }

        ::ng-deep s25-ng-meeting-pattern-grid .grid--column-headers > .grid--column-header:not(:first-child) {
            border-left-width: calc(var(--border-width) + var(--dow-separator-thickness)) !important;
            margin-left: calc(0px - var(--border-width) - var(--dow-separator-thickness));
        }

        ::ng-deep s25-ng-meeting-pattern-grid .grid--column-headers > .grid--column-header:not(:first-child) > .tick {
            border-left-width: calc(var(--border-width) + var(--dow-separator-thickness)) !important;
            margin-left: calc(0px - var(--dow-separator-thickness));
        }

        .overlapData,
        .undersizedRoomData {
            display: grid;
            grid-template-columns: 10em auto;
            margin-top: 1em;
            padding: 0.5em;
            border: 1px solid black;
            background: #eee;
        }

        .overlapData label,
        .undersizedRoomData label {
            font-weight: bold;
        }

        ::ng-deep .nm-party--on s25-ng-meeting-pattern-grid .overlapData,
        ::ng-deep .nm-party--on s25-ng-meeting-pattern-grid .undersizedRoomData {
            background: #222;
        }

        .confirmOverlap,
        .confirmUndersizedRoom {
            display: grid;
            gap: 1em;
        }

        .confirmOverlap .overlapData,
        .confirmUndersizedRoom .undersizedRoomData {
            margin: 0;
        }
    `,
})
export class S25MeetingPatternGridComponent implements OnChanges, OnInit, OnDestroy {
    @Input({ required: true }) board: SwarmSchedule.BoardI;

    // Outputs
    @Output() exit = new EventEmitter<boolean>(); // Emitting will unload the component. Boolean indicates whether to refresh table in parent

    // Template views
    @ViewChild(S25DowGridComponent) dowGridComponent: S25DowGridComponent<MPG.HeaderData, MPG.RowData, MPG.ItemData>;
    @ViewChild("shareModal") shareModal: S25ModalComponent;
    @ViewChild("overlapModal") overlapModal: S25ModalComponent;
    @ViewChild("confirmOverlap") confirmOverlap: S25ModalComponent;
    @ViewChild("confirmUndersizedRoom") confirmUndersizedRoom: S25ModalComponent;

    // Variables
    prefs: Awaited<ReturnType<typeof MeetingPatternGridService.getChartPreferences>>;
    dowGridDataSource: MPG.DataSource;
    allItems: MPG.Item[]; // Including ones which are not visible
    data: MPG.Data;
    rowIndexMap: Map<MPG.Row["id"], number>;
    username: string;
    sessionId: string;
    minFillRatio: number = 0;
    lastMessage: EpochTimestamp = Date.now(); // Including our own messages
    pollInterval: Milliseconds = S25MeetingPatternGridComponent.DEFAULT_POLL_INTERVAL;
    allowXDrag: boolean = false;
    sharedUsers: SwarmSchedule.BoardI["sharedUsers"] = [];
    voidContact: {};
    loading = {
        filter: false,
        sort: false,
        save: false,
    };
    filters = {
        show: false,
        name: "",
        capacityMin: null as number,
        capacityMax: null as number,
        features: [] as S25ItemI[],
        partitions: [] as S25ItemI[],
    };
    boardData: Awaited<ReturnType<typeof BoardUploadService.createBoardFromOptimizer>>["root"];
    allDows: string[];
    sortOrder: "default" | "relevance" = "default";
    is24Hours = false;
    relevanceSortItem?: MPG.Item;
    overlapData: {
        roomName: string;
        dow: string;
        startTime: string;
        endTime: string;
        sectionA: string;
        sectionB: string;
    }[];
    undersizedRoomData: {
        event: MPG.Item;
        room: MPG.Row;
    };

    protected readonly BOARD_CONST = BOARD_CONST;
    private static DEFAULT_POLL_INTERVAL = 2_000;

    constructor(private changeDetector: ChangeDetectorRef) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.board) this.refresh();
    }

    async ngOnInit() {
        this.dowGridDataSource = {
            getData: this.getBoardData,
            onItemsPickedUp: this.onItemsPickedUp,
            onItemsDragged: this.onItemsDragged,
            onItemsPutDown: this.onItemsPutDown,
            afterItemsPutDown: this.afterItemsPutDown,
            onUndo: this.onUndoRedo,
            onRedo: this.onUndoRedo,
            poll: this.poll,
            postProcessItems: this.postProcessItems,
        };

        this.prefs = await MeetingPatternGridService.getChartPreferences();
        this.changeDetector.detectChanges();

        // These do *not* need to finish loading before we can start rendering the grid
        const [username, sessionId] = await Promise.all([
            ContactService.getCurrentUsername(),
            UserprefService.getSessionId(),
        ]);
        this.username = username;
        this.sessionId = sessionId;
    }

    ngOnDestroy() {
        this.invalidateCache();
    }

    invalidateCache() {
        CacheRepository.invalidateByMethod("BoardUploadService.createBoardFromOptimizer");
    }

    refresh() {
        this.invalidateCache();
        return this.dowGridComponent?.refresh?.();
    }

    @Bind
    async getBoardData(query: Grid.DataQuery): Promise<MPG.Data> {
        if (query.force) this.invalidateCache();
        const [boardData, is24Hours] = await Promise.all([
            BoardUploadService.createBoardFromOptimizer(this.board),
            UserprefService.getIs24HourTime(),
        ]);
        this.boardData = boardData.root;
        this.minFillRatio = boardData.root?.minFillRatio || 0;
        this.allowXDrag = !!boardData.root?.allowXDrag;
        this.is24Hours = is24Hours;

        const rows: MPG.Row[] = boardData?.root?.room?.map(MPGUtil.mapLocationToRow);
        rows.sort(this.sortByIdAndName);
        this.rowIndexMap = new Map(rows.map((row, index) => [row.id, index]));

        const uniqueEvents = MeetingPatternGridService.getUniqueEvents(boardData.root.item);
        const items: MPG.Item[] = MeetingPatternGridService.getItems(null, uniqueEvents).map((event) => ({
            id: event.uuid,
            top: (this.rowIndexMap.get(event.roomId) / rows.length) * 100,
            height: 100 / rows.length, // Height is one row height
            draggable: event.draggable,
            linkedItems: new Set(event.linkedItems.filter((item) => item.sync).map((item) => item.objRef.uuid)),
            data: MPGUtil.getItemData(event),
            ariaLabel: "", // Set in dow grid
        }));

        // Make sure all present dow patterns are included
        const allDows = [...BOARD_CONST.allDows];
        const allDowsSet = new Set(allDows);
        for (let item of items) {
            if (!allDowsSet.has(item.data.dow)) {
                allDows.push(item.data.dow);
                allDowsSet.add(item.data.dow);
            }
        }
        this.allDows = allDows;
        this.changeDetector.detectChanges(); // Need to send "allDows" to dowGrid before returning

        this.data = { items, rows };
        return this.data;
    }

    @Bind
    async onItemsPickedUp(items: MPG.Item[]): Promise<boolean> {
        MPGUtil.updateItemTimestamps(items);
        MPGUtil.updateCandidateData(this.data, items);
        const itemTos = MPGUtil.getItemTos(this.data, items);
        return MeetingPatternGridService.lockItems(this.board.boardUUID, itemTos);
    }

    @Throttle(300, true)
    onItemsDragged(items: MPG.Item[]): void {
        MPGUtil.updateItemTimestamps(items);
        MPGUtil.updateCandidateData(this.data, items);
        const itemTos = MPGUtil.getItemTos(this.data, items);
        MeetingPatternGridService.dispatch(this.board.boardUUID, itemTos);
        this.changeDetector.detectChanges(); // Update the time bubbles

        AriaLive.announce(this.getAriaLivePosition(items[0]), true);
    }

    getAriaLivePosition(item: MPG.Item) {
        const location = MPGUtil.getLocationFromTop(this.data, item);
        const time = S25Util.date.ariaTimeString(S25Util.date.toTimeStrFromHours(item.data.startHour, this.is24Hours));
        if (location.id === item.data.roomId) return `${time}`;
        return `${location}, ${time}`;
    }

    doItemsOverlap(
        a: Grid._Item<DowGrid._CustomItemData<MPG.ItemData>>,
        b: Grid._Item<DowGrid._CustomItemData<MPG.ItemData>>,
    ): boolean {
        if (a.data.isFrame || b.data.isFrame) return false; // No frames
        if (a.data._isShadow && b.data._isShadow) return false; // Ignore if both are shadows
        if (!a.draggable && !b.draggable) return false; // Ignore if neither can be moved

        // No meeting pattern overlap
        if (b.candidate.startHour >= a.candidate.endHour || b.candidate.endHour <= a.candidate.startHour) return false;

        const bound = a.linkedItems.has(b.id);
        if (bound) return false; // Ignore bound items

        const temporalOverlap = this.doOccurrencesOverlap(a.candidate.occs, b.candidate.occs);
        if (!temporalOverlap) return false; // No temporal overlap

        return true;
    }

    /**
     * Check whether two sets of occurrences have any overlap
     * @param A SORTED array of occurrences
     * @param B SORTED array of occurrences
     */
    doOccurrencesOverlap<T extends { reservation_start_dt: ISODateString; reservation_end_dt: ISODateString }[]>(
        A: T,
        B: T,
    ) {
        if (!A?.length || !B?.length) return false;

        let a = 0;
        let b = 0;
        let occA = A[a];
        let occB = B[b];
        while (occA && occB) {
            if (occA.reservation_start_dt >= occB.reservation_end_dt) b++;
            else if (occA.reservation_end_dt <= occB.reservation_start_dt) a++;
            else return true;
            occA = A[a];
            occB = B[b];
        }

        return false;
    }

    @Bind
    async onItemsPutDown(items: MPG.Item[]): Promise<boolean> {
        MPGUtil.updateItemTimestamps(items);

        MPGUtil.updateCandidateData(this.data, items);

        const valid = await this.validateNewPosition(items);
        if (!valid) return false;

        return this.onItemsMoved(items);
    }

    async onItemsMoved(items: MPG.Item[]) {
        const itemTos = MPGUtil.getItemTos(this.data, items);
        const ok = await MeetingPatternGridService.unlockItems(this.board.boardUUID, itemTos);
        if (!ok) return false;

        // Update location
        for (let item of items) {
            const locationId = Number(MPGUtil.getLocationFromTop(this.data, item).id);
            item.data.roomId = locationId;
            item.data.sourceRoomId = locationId;
        }
    }

    async validateNewPosition(items: MPG.Item[]) {
        // Warn on overlap
        const roomId = items[0].candidate.roomId;
        const roomItems = this.data.items.filter((item) => item.data.roomId === roomId) as Grid._Item<
            DowGrid._CustomItemData<MPG.ItemData>
        >[];
        for (const item of items as Grid._Item<DowGrid._CustomItemData<MPG.ItemData>>[]) {
            for (const roomItem of roomItems) {
                if (item.id === roomItem.id) continue; // Same item
                const overlap = this.doItemsOverlap(item, roomItem);
                if (!overlap) continue;

                this.overlapData = [this.getOverlappingData([item, roomItem])];

                const ok = await this.confirmOverlap.open();
                if (!ok) return false;
            }
        }

        // Warn if location is too small (and not UNASSIGNED row)
        if (roomId !== -1) {
            for (const item of items) {
                const room = item.candidate.room;
                if (item.data.headCount <= room.data.maxCapacity) continue;
                this.undersizedRoomData = { event: item, room };

                const ok = await this.confirmUndersizedRoom.open();
                if (!ok) return false;
            }
        }

        return true;
    }

    @Bind
    afterItemsPutDown() {
        this.recreateFrames();
    }

    @Bind
    async poll(): Promise<MPG.PollData> {
        const messages = await MeetingPatternGridService.poll(this.board.boardUUID);
        const pollData: MPG.PollData = { items: {} };
        for (let message of messages) this.processMessage(pollData, message);

        // Backoff polling if there's no activity
        // For every 10 minutes of inactivity, double the poll interval, maxing out around 1 minute, after 50 minutes
        const minutes = (Date.now() - this.lastMessage) / S25Const.ms.min;
        const exp = Math.min(Math.floor(minutes / 10), 5);
        this.pollInterval = S25MeetingPatternGridComponent.DEFAULT_POLL_INTERVAL * Math.pow(2, exp);

        return pollData;
    }

    @Bind
    postProcessItems(items: Grid.Item<MPG.ItemData>[]) {
        if (!items?.length) return;
        const frames = this.createFrames(items);
        items.push(...frames);
    }

    recreateFrames() {
        const items = this.dowGridComponent.getItems();
        S25Util.array.inplaceFilter(items, (item) => !item.data.isFrame); // Remove any existing frames
        const frames = this.createFrames(items);
        for (let frame of frames) GridUtil.updateItemPosition(this.dowGridComponent.data, frame);
        items.push(...frames);
    }

    createFrames(items: Grid._Item<MPG.ItemData>[]) {
        const groups = S25Util.array.groupBy(items, (item) => `${item.data.roomId} - ${item.data.dow}`); // Groups by location and DOW
        const frames: Grid.Item<MPG.ItemData>[] = [];
        for (let group of Object.values(groups)) {
            group.sort(this.dowGridComponent.sortByDowAndTime);

            // Here we need to find stacked items and create group items which put borders around them
            let left = group[0].left;
            let right = group[0].left + group[0].width;
            let leftHour = group[0].data.startHour;
            let rightHour = group[0].data.endHour;
            if (rightHour < leftHour) rightHour += 24; // Spans midnight
            let frame: Set<Grid.Item<MPG.ItemData>> = new Set([group[0]]);
            for (let i of S25Util.range(1, group.length)) {
                const item = group[i];
                const overlaps = item.data.startHour < rightHour; // Same location, same dow, overlapping times
                if (overlaps) {
                    frame.add(item);
                    right = Math.max(right, item.left + item.width);
                    rightHour = Math.max(rightHour, item.data.endHour);
                }
                if (frame.size !== 1 && (!overlaps || i === group.length - 1)) {
                    // The end of a frame
                    const frameItem: Grid._Item<MPG.ItemData> = {
                        ...item,
                        id: `group-${item.id}`,
                        left,
                        width: right - left,
                        draggable: false,
                        noInteraction: true,
                        data: {
                            ...item.data, // Doesn't really matter
                            isFrame: true,
                            frame: { frame },
                        },
                        _gridData: { ...item._gridData },
                    };
                    frames.push(frameItem);
                    for (let frameItem of frame) frameItem.data.frame = { frame, item: frameItem };
                }
                if (!overlaps) {
                    left = item.left;
                    right = item.left + item.width;
                    leftHour = item.data.startHour;
                    rightHour = item.data.endHour;
                    frame = new Set([item]);
                }
            }
        }
        return frames;
    }

    sortByIdAndName(a: MPG.Row, b: MPG.Row) {
        if (a.id === -1 && b.id !== -1) return -1;
        if (b.id === -1 && a.id !== -1) return 1;
        if (a.data.name.toLowerCase() < b.data.name.toLowerCase()) return -1;
        if (a.data.name.toLowerCase() > b.data.name.toLowerCase()) return 1;
        return 0;
    }

    // Called from the event item component
    onSortByRelevance(item: MPG.Item) {
        const scores = MPGUtil.scoreRows(this.data.rows, item, this.minFillRatio);
        this.sortRows((a, b) => scores.get(b.id) - scores.get(a.id));
        this.sortOrder = "relevance";
        this.relevanceSortItem = item;
        this.changeDetector.detectChanges();
    }

    sortRows<T extends Grid.Row<MPG.RowData>>(comparator: (a: T, b: T) => number) {
        // Because the DOW Grid component doesn't copy any data we can alter the rows and items directly
        // to change the VirtualGrid data.

        this.startLoading("sort");

        // Sort rows
        this.data.rows.sort(comparator);

        // Update row index map
        this.rowIndexMap = new Map(this.data.rows.map((row, i) => [row.id, i]));

        // Set new top values
        const items = this.dowGridComponent.getItems();
        for (let item of items) {
            item.top = this.calculateTopValue(item.data.roomId);
        }

        this.recreateFrames();

        // Update grid
        this.dowGridComponent.staticRefresh(this.dowGridComponent.data); // Since we altered data in place we'll just pass it the same object
        this.stopLoading("sort");
    }

    calculateTopValue(rowId: MPG.Row["id"]): number {
        return (this.rowIndexMap.get(rowId) / this.data.rows.length) * 100;
    }

    processMessage(pollData: MPG.PollData, message: SwarmSchedule.Message): MPG.PollData | undefined {
        this.lastMessage = Date.now();
        if ("sessionId" in message && message.sessionId === this.sessionId) return; // Ignore our own messages
        if (message.item.itemUUID === `unshare-${this.username}`) this.exit.emit(true); // This will unload the component
        switch (message.category) {
            case "default":
                return this.processDefaultMessage(pollData, message as SwarmSchedule.DefaultMessage);
            case "user":
                return this.processUserMessage(pollData, message as SwarmSchedule.UserMessage);
        }
    }

    processDefaultMessage(pollData: MPG.PollData, message: SwarmSchedule.DefaultMessage): MPG.PollData | undefined {
        const item = this.dowGridComponent._itemById.get(message.item.itemUUID) as MPG.Item;
        if (!item) return; // Unknown item

        if (message.item.timestamp <= item.data.lastChange) return; // Ignore superseded or already executed changes
        item.data.lastChange = message.item.timestamp;
        item.data.roomId = message.item.roomId;

        pollData.items[message.item.itemUUID] = {
            moveTo: {
                top: this.calculateTopValue(Number(message.item.rowCol.rowUUID)),
                dow: message.item.dow,
                startHour: S25Util.date.dateToHours(message.item.start),
                endHour: S25Util.date.dateToHours(message.item.end),
            },
        };

        AriaLive.announce(`${item.data.name} was moved to ${this.getAriaLivePosition(item)}`, true);

        // If we have any movement, redo frames
        pollData.postPoll = () => {
            this.recreateFrames();
        };
    }

    /**
     * Processes a user type message from the meeting pattern grid service
     * @return {void} Nothing, because we set the data directly on the item
     */
    processUserMessage(pollData: MPG.PollData, message: SwarmSchedule.UserMessage): undefined {
        if (message.username === this.username) return; // Ignore our own messages. These are not ignore by session, because user messages do not have session affinity

        const item = this.dowGridComponent._itemById.get(message.item.itemUUID) as MPG.Item;
        item.data.activeUser = message.type === "user-on" ? message.username : null; // Set or reset the active user upon user-on/user-off messages
    }

    onShare() {
        this.sharedUsers = [...this.board.sharedUsers];
        this.shareModal.open();
    }

    onShareSave() {
        // Share and unshare as needed
        const pre = new Set(this.board.sharedUsers.map((user) => user.username));
        const post = new Set(this.sharedUsers.map((user) => user.username));
        for (let user of pre) {
            if (!post.has(user)) BoardService.unshareBoard(this.board.boardId, this.board.boardUUID, user);
        }
        for (let user of post) {
            if (!pre.has(user)) BoardService.shareBoard(this.board.boardId, this.board.boardUUID, user);
        }
        this.board.sharedUsers = this.sharedUsers;
        this.shareModal.close();
    }

    addSharedUser(user: { itemName: string }) {
        const alreadyShared = this.sharedUsers.some((item) => item.username === user.itemName);
        if (!alreadyShared) this.sharedUsers.push({ username: user.itemName });
        // Clear contact from dropdown
        this.voidContact = { itemName: "", itemId: "" };
        this.changeDetector.detectChanges();
    }

    resetSort() {
        this.sortRows(this.sortByIdAndName);
        this.sortOrder = "default";
        this.changeDetector.detectChanges();
    }

    runFilters() {
        if (this.loading.filter) return;
        this.startLoading("filter");

        const features = new Set(this.filters.features.map((feature) => Number(feature.itemId)));
        const partitions = new Set(this.filters.partitions.map((partition) => Number(partition.itemId)));
        for (let row of this.data.rows) {
            row.hidden = !this.applyFiltersToRow(row, {
                name: this.filters.name.toLowerCase(),
                capacityMin: this.filters.capacityMin,
                capacityMax: this.filters.capacityMax,
                features,
                partitions,
            });
        }

        this.dowGridComponent.staticRefresh(this.dowGridComponent.data);
        this.stopLoading("filter");
    }

    applyFiltersToRow(
        row: Grid.Row<MPG.RowData>,
        data: {
            name: string;
            capacityMin: number;
            capacityMax: number;
            features: Set<number>;
            partitions: Set<number>;
        },
    ) {
        if (row.id === -1) return true; // Unassigned row is always present
        if (row.data.name && !row.data.name.toLowerCase().includes(data.name)) return false;
        if (data.capacityMin && row.data.maxCapacity < data.capacityMin) return false;
        if (data.capacityMax && row.data.maxCapacity > data.capacityMax) return false;
        if (data.features.size && !row.data.features.some((feature) => data.features.has(feature.id))) return false;
        if (data.partitions.size && !data.partitions.has(row.data.partition.id)) return false;
        return true;
    }

    clearFilters() {
        if (this.loading.filter) return;
        this.filters.name = "";
        this.filters.capacityMin = null;
        this.filters.capacityMax = null;
        this.filters.features.splice(0, Number.MAX_SAFE_INTEGER);
        this.filters.partitions.splice(0, Number.MAX_SAFE_INTEGER);
        this.runFilters();
    }

    setLoading(type: keyof typeof this.loading, isLoading: boolean) {
        this.loading[type] = isLoading;
        this.changeDetector.detectChanges();
    }

    startLoading(type: keyof typeof this.loading) {
        this.setLoading(type, true);
    }

    stopLoading(type: keyof typeof this.loading) {
        this.setLoading(type, false);
    }

    @Bind
    getOverlappingData(section: Grid._Item<DowGrid._CustomItemData<MPG.ItemData>>[]) {
        const roomId = section[1].candidate.roomId;
        const room = this.data.rows[this.rowIndexMap.get(roomId)];
        const roomName = room?.data.name;
        const dow = section[0].candidate.dow;
        const start = Math.max(section[0].candidate.startHour, section[1].candidate.startHour);
        const end = Math.min(section[0].candidate.endHour, section[1].candidate.endHour);
        const startTime = S25Util.date.toTimeStrFromHours(start, this.is24Hours);
        const endTime = S25Util.date.toTimeStrFromHours(end, this.is24Hours);
        const sectionA = section[0].data.name;
        const sectionB = section[1].data.name;

        return { roomName, dow, startTime, endTime, sectionA, sectionB };
    }

    @Telemetry({ category: "MPG", type: "OptimizerSend" })
    async onSave() {
        AriaLive.announce("Saving", true);
        this.startLoading("save");

        let [ok, error] = await S25Util.Maybe(this.dowGridComponent.forcePoll()); // Make sure we have the latest data
        if (error) {
            S25Util.showError(error);
            this.stopLoading("save");
            return;
        }

        const overlap = this.findOverlappingSections();
        if (overlap.length) {
            this.stopLoading("save");
            this.overlapData = overlap.map(this.getOverlappingData);
            await this.overlapModal.open();
            this.overlapData = null;
            return;
        }

        const model = {
            boardId: this.boardData.boardId,
            boardUUID: this.boardData.boardUUID,
            origResults: this.boardData.origResults,
            origResultsMap: this.boardData.origResultsMap,
            items: this.dowGridComponent.data.items
                .filter((item) => !item.data._isShadow && !item.data.isFrame)
                .map((item) => {
                    const event = item.data.event;
                    return {
                        origDow: event.origDow,
                        origStartTime: event.origStartTime,
                        itemName: event.itemName,
                        roomId: item.data.roomId,
                        eventId: event.eventId,
                        profileId: event.profileId,
                        groupId: event.groupId,
                        linkedItems: event.linkedItems,
                        moved: event.moved,
                        newpattern: event.newpattern,
                        objRef: event.objRef,
                        dow: item.data.dow,
                        start: new Date(
                            S25Util.date.toS25ISODateTimeStr(S25Util.date.toTimeStrFromHours(item.data.startHour)),
                        ),
                        startHour: item.data.startHour,
                        startTime: S25Util.date.toS25ISOTimeStrFromHours(item.data.startHour),

                        end: new Date(
                            S25Util.date.toS25ISODateTimeStr(S25Util.date.toTimeStrFromHours(item.data.endHour)),
                        ),
                        endHour: item.data.endHour,
                        endTime: S25Util.date.toS25ISOTimeStrFromHours(item.data.endHour),
                        profileCode: event.profileCode,
                        daysOfWeek: item.data.dow.split(""),
                        occs: event.occs,
                    };
                }),
        } as unknown as SwarmSchedule.ModelI;

        [ok, error] = await S25Util.Maybe(BoardService.saveBoard(model));
        if (error) S25Util.showError(error);
        this.stopLoading("save");
        AriaLive.announce("Finished saving", true);
    }

    toggleFilters() {
        this.filters.show = !this.filters.show;
        this.changeDetector.detectChanges();
    }

    findOverlappingSections() {
        const sections: Grid._Item<DowGrid._CustomItemData<MPG.ItemData>>[][] = [];

        const items = this.dowGridComponent.getItems();
        // Group by location and DOW
        const groups = S25Util.array.groupBy(items, (item) => `${item.data.roomId} - ${item.data.dow}`);
        for (let group of Object.values(groups)) {
            if (group[0].data.roomId === -1) continue; // Ignore unassigned row
            group.sort(this.dowGridComponent.sortByDowAndTime);

            for (const i of S25Util.range(group.length)) {
                const item = group[i];
                if (item.data.isFrame) continue; // Ignore frames
                const itemRight = item.left + item.width;

                for (const j of S25Util.range(i + 1, group.length)) {
                    const candidate = group[j];

                    if (candidate.left >= itemRight) break; // No more overlap

                    const overlap = this.doItemsOverlap(item, candidate);
                    if (overlap) {
                        sections.push([item, candidate]);
                    }
                }
            }
        }

        return sections;
    }

    @Bind
    async onUndoRedo(items: MPG.Item[]) {
        await this.onItemsMoved(items);
        this.recreateFrames();
    }
}

export namespace MPG {
    export type DataSource = DowGrid.DataSource<MPG.HeaderData, MPG.RowData, MPG.ItemData>;
    export type Data = DowGrid._Data<MPG.HeaderData, MPG.RowData, MPG.ItemData>;
    export type Row = Grid.Row<MPG.RowData>;
    export type Item = DowGrid._Item<MPG.ItemData>;
    export type PollData = DowGrid.PollData<MPG.ItemData>;

    export type HeaderData = {};

    export type RowData = {
        name: string;
        features: { id: number; name: string }[];
        partition?: { id: number; name: string };
        maxCapacity: number;
    };

    export type ItemData = DowGrid.CustomItemData & {
        name: string;
        event: MeetingPatternGrid.Item;
        room?: Grid.Row<RowData>;
        roomId: MeetingPatternGrid.Item["roomId"];
        profileCode: MeetingPatternGrid.Item["profileCode"];
        uuid: MeetingPatternGrid.Item["uuid"];
        sourceRoomId: MeetingPatternGrid.Item["roomId"];
        visible?: boolean;
        organization?: {
            id: number;
            name: string;
            partitions: {
                priority: number;
                list: { id: number; name: string }[];
            }[];
        };
        features: { id: number; name: string }[];
        headCount: number;
        canHaveSOC: boolean;
        activeUser?: string;
        lastChange: Proto.EpochTimestamp;
        isFrame?: boolean;
        frame?: { frame: Set<MPG.Item>; item?: MPG.Item };
        broughtToFront?: boolean;
        occs: MeetingPatternGrid.Item["occs"];
    };
}
